<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note Invoice Applied</h4>
    </span>
    <!-- <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div> -->
    <vs-table stripe border description :sst="true" :data="data">
      <template slot="thead">
        <vs-th>Invoice Number</vs-th>
        <vs-th>Paid Value</vs-th>
        <vs-th>Payment Date</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].invoice_code }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{ formatPrice(tr.paid_value) }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{ dateFormat(tr.payment_date) }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment';
export default {
  components: {
    Datepicker,
  },
  props: {
    dn_id: {
      type: Number,
    },
    dn_code: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        data: [],
        dn_value: 0,
        return_value: 0,
        dn_value_show: 0,
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    formatPrice(angka, prefix = "") {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("YYYY-MM-DD");
      }
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/invoice-line/" + this.dn_id, {
          params: {
            territory_id: this.$userLogin.territory_id,
            territory_area_id: this.$userLogin.territory_area_id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data = resp.data.records
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.dn_id) {
      this.getData();
    }
  },
  computed: {
  },
  watch: {
    dn_id() {
      Object.assign(this.$data, this.initialState());
      this.getData();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>