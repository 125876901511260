<style>
  .input-readonly > div > .vs-inputx {
    background-color: #e6e6e6;
  }
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DN Number"
          name="DN Number"
          v-model="dn_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Notes"
          name="Notes"
          v-model="notes"
          placeholder="Notes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Number"
          name="Supplier Credit Note Number"
          v-model="cn_number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Date"
          name="Supplier Credit Note Date"
          v-model="cn_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Number"
          name="Tax Return Number"
          v-model="tr_number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Date"
          name="Tax Return Date"
          v-model="tr_date"
          readonly
        />
      </div>
    </div>  
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Price</vs-th>
        <vs-th>Damaged Quantity</vs-th>
        <vs-th>Quantity</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
        <vs-th>Total</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span><br>
              <span class="w-5/5">{{ data[indextr].batch }}</span>
            </div>
          </vs-td>
          
          <vs-td>
            <div class="vx-row ml-0">
              {{ parseFloat(table.purchaseOrder.lines.filter(i => {
                return i.item_unit_id = data[indextr].item_unit_id
              })[0].price_pcs).toFixed(2).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{ data[indextr].damage_quantity }} {{ data[indextr].unit_name }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Quantity Return"
                label="Quantity Return"
                :value="
                  data[indextr].return_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full input-readonly"
                name="Quantity Debit Note"
                label="Quantity Debit Note"
                :value="
                  data[indextr].dn_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                @input="formatNumber(indextr, 'dn_quantity')"
                placeholder="Quantity DN"
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Value"
                label="Return Value"
                :value="
                  data[indextr].return_value.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full input-readonly"
                name="Debit Note Value"
                label="Debit Note Value"
                :value="
                  data[indextr].dn_value.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                @input="formatNumber(indextr, 'dn_value')"
                placeholder="DN Value"
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Tax"
                label="Return Tax"
                :value="
                  data[indextr].return_tax.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full input-readonly"
                name="Debit Note Tax"
                label="Debit Note Tax"
                :value="
                  data[indextr].dn_tax.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Return Total"
                label="Return Total"
                :value="
                  data[indextr].return_total_value.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full input-readonly"
                name="Debit Note Total"
                label="Debit Note Total"
                :value="
                  data[indextr].dn_total_value.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DPP"
          name="DPP"
          v-model="dpp"
          readonly
        />
      </div>
    </div>  
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax"
          name="Tax"
          v-model="total_ppn"
          readonly
        />
      </div>
    </div>  
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Total"
          name="Total"
          v-model="total_dn"
          readonly
        />
      </div>
    </div>  
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Difference Value"
          name="Difference Value"
          v-model="total_dn"
          readonly
        />
      </div>
    </div>  
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    dn_id: {
      type: Number,
    },
    dn_code: {
      type: String,
    },
    cn_number: {
      type: String,
    },
    cn_date: {
      type: String,
    },
    tr_date: {
      type: String,
    },
    tr_number: {
      type: String,
    },
    notes: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          data: [],
        },
        dn:{}
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    paramData() {
      return {
        invoice_code: this.invCode,
        purchase_order_code: this.vCode,
        supplier_code: this.supplierCode,
        invoice_date: this.invDate,
        due_date: this.dueDate,
        bank_id: this.bank_name.id ? this.bank_name.id : this.bank_name.ID,
        bank_name: this.bank_name.name
          ? this.bank_name.name
          : this.bank_name.Name,
        account_number: this.account_number,
        invoice_value: this.table.total_inv_value,
        paid_value: this.total_value,
      };
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.postData();
        }
      });
    },
    // postData() {
    //   this.$http
    //     .post("/api/v1/purchase-payment", this.paramData())
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.handleClose();
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/dn-line/" + this.dn_id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.data = resp.data.records;
            this.table.purchaseOrder = resp.data.purchaseOrder;
            this.dn = resp.data.debitNote;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.dn_id) {
      this.getData();
    }
  },
  computed: {
    dpp() {
      return this.table.data.map(item => item.dn_value).reduce((prev, curr) => prev + curr, 0).toFixed(2).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    total_ppn() {
      return this.table.data.map(item => item.dn_tax).reduce((prev, curr) => prev + curr, 0).toFixed(2).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    total_dn() {
      return this.table.data.map(item => item.dn_total_value).reduce((prev, curr) => prev + curr, 0).toFixed(2).toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  watch: {
    dn_id() {
      Object.assign(this.$data, this.initialState());
      this.getData();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>